var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showEditMailSettings)?_c('ModalEditLocationMailSettings',{attrs:{"showModal":_vm.showEditMailSettings,"onClickCancel":_vm.hideModal,"settings":_vm.settings,"selectedChannel":_vm.selectedChannel},on:{"mailSettingsUpdated":_vm.mailSettingsUpdated}}):_vm._e(),_c('ui-section-header',{attrs:{"showEdit":true,"editFunction":_vm.setShowEditMailSettings},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Reservation mail settings")]},proxy:true}])}),_c('div',{staticClass:"tabs is-boxed"},[_c('ul',[_c('li',{class:{
          'is-active': _vm.selectedChannel === 0,
        }},[_c('a',{on:{"click":function($event){_vm.selectedChannel = 0}}},[_vm._v("Global")])]),_c('li',{class:{
          'is-active': _vm.channelId === _vm.selectedChannel,
        },attrs:{"id":"id_tabs_yieldlist"}},[_c('a',{on:{"click":function($event){_vm.selectedChannel = _vm.channelId}}},[_vm._v(_vm._s(_vm.channel.Name))])])])]),_c('table',{staticClass:"table is-fullwidth is-striped is-hoverable"},[_vm._m(0),_c('tbody',_vm._l((_vm.settings.filter(
          (s) => s.ChannelId === _vm.selectedChannel
        )),function(setting){return _c('tr',{key:setting.Key},[_c('td',[_vm._v(_vm._s(_vm._f("getMeetingtypeName")(setting.MeetingtypeId)))]),_c('td',[_vm._v(_vm._s(setting.ChangeType))]),_c('td',{staticClass:"has-text-right"},[_c('span',{staticClass:"icon"},[(setting.ReceiveMail)?_c('font-awesome-icon',{staticClass:"has-text-success",attrs:{"icon":['fas', 'check']}}):_vm._e(),(!setting.ReceiveMail)?_c('font-awesome-icon',{staticClass:"has-text-danger",attrs:{"icon":['fas', 'times']}}):_vm._e()],1)]),_c('td',[(setting.EmailAddress)?_c('div',{staticClass:"tags"},_vm._l((setting.EmailAddress.split(
                ','
              ).filter((m) => m.length > 0)),function(address,index){return _c('span',{key:index,staticClass:"tag is-link",class:{
                'has-background-light has-text-grey-dark':
                  !setting.ReceiveMail,
              }},[_vm._v(_vm._s(address)+" ")])}),0):_vm._e()])])}),0),_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v(" ")]),_c('td',[_c('a',{staticClass:"has-icon",on:{"click":_vm.clearAll}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{staticClass:"has-text-danger",attrs:{"icon":['fas', 'trash-alt']}})],1),_c('span',[_vm._v("Clear all")])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Meeting type")]),_c('th',[_vm._v("Action")]),_c('th',{staticClass:"has-text-right"},[_vm._v("Receive mail")]),_c('th',[_vm._v("Email address")])])])
}]

export { render, staticRenderFns }