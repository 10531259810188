<template>
  <div>
    <ModalEditLocationMailSettings
      v-if="showEditMailSettings"
      :showModal="showEditMailSettings"
      :onClickCancel="hideModal"
      :settings="settings"
      :selectedChannel="selectedChannel"
      @mailSettingsUpdated="mailSettingsUpdated"
    />

    <ui-section-header :showEdit="true" :editFunction="setShowEditMailSettings">
      <template v-slot:title>Reservation mail settings</template>
    </ui-section-header>

    <div class="tabs is-boxed">
      <ul>
        <li
          :class="{
            'is-active': selectedChannel === 0,
          }"
        >
          <a @click="selectedChannel = 0">Global</a>
        </li>

        <li
          id="id_tabs_yieldlist"
          :class="{
            'is-active': channelId === selectedChannel,
          }"
        >
          <a @click="selectedChannel = channelId">{{ channel.Name }}</a>
        </li>
      </ul>
    </div>

    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>Meeting type</th>
          <th>Action</th>
          <th class="has-text-right">Receive mail</th>
          <th>Email address</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="setting in settings.filter(
            (s) => s.ChannelId === selectedChannel
          )"
          :key="setting.Key"
        >
          <td>{{ setting.MeetingtypeId | getMeetingtypeName }}</td>
          <td>{{ setting.ChangeType }}</td>
          <td class="has-text-right">
            <span class="icon">
              <font-awesome-icon
                v-if="setting.ReceiveMail"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
              <font-awesome-icon
                v-if="!setting.ReceiveMail"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
            </span>
          </td>
          <td>
            <div class="tags" v-if="setting.EmailAddress">
              <span
                v-for="(address, index) in setting.EmailAddress.split(
                  ','
                ).filter((m) => m.length > 0)"
                :key="index"
                class="tag is-link"
                :class="{
                  'has-background-light has-text-grey-dark':
                    !setting.ReceiveMail,
                }"
                >{{ address }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">&nbsp;</td>
          <td>
            <a @click="clearAll" class="has-icon">
              <span class="icon">
                <font-awesome-icon
                  :icon="['fas', 'trash-alt']"
                  class="has-text-danger"
                />
              </span>
              <span>Clear all</span>
            </a>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import locationProvider from '@/providers/location'

export default {
  components: {
    ModalEditLocationMailSettings: () =>
      import('@/components/Locations/Settings/ModalEditLocationMailSettings'),
  },

  props: {},

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isLoading: false,
      selectedChannel: 0,
      settings: [],
      showEditMailSettings: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['location']),
  },

  created() {
    this.getLocationMailSettings()
  },

  methods: {
    getLocationMailSettings() {
      let self = this
      self.isLoading = true

      locationProvider.methods
        .getLocationMailSettings(this.location.Id)
        .then((response) => {
          self.settings = response.data

          for (let i = 0; i < self.settings.length; i++) {
            self.settings[i].Key =
              self.settings[i].ChannelId +
              '-' +
              self.settings[i].MeetingtypeId +
              '-' +
              self.settings[i].ChangeType
          }
        })
        .catch((e) => {
          ////console.log(e.response)
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    duplicateGlobalToChannel() {
      let texts = cleanSource(this.settings.filter((s) => s.ChannelId === 0))
      let newTexts = []
      for (let i = 0; i < texts.length; i++) {
        let text = texts[i]

        text.Id = 0
        text.ChannelId = this.selectedChannel
        text.Key =
          text.ChannelId + '-' + text.MeetingtypeId + '-' + text.ChangeType

        newTexts.push(text)
      }

      this.settings.push(...newTexts)
      this.saveMailSettings()
    },

    saveMailSettings() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        locationProvider.methods
          .saveLocationMailSettings(self.location.Id, self.settings)
          .then((response) => {
            if (response.status === 200) {
              this.getLocationMailSettings()
            }
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    mailSettingsUpdated() {
      this.getLocationMailSettings()
    },

    setShowEditMailSettings() {
      this.showEditMailSettings = true
    },

    hideModal() {
      this.showEditMailSettings = false
    },

    clearAll() {
      let settings = this.settings.filter(
        (s) => s.ChannelId === this.selectedChannel
      )

      for (let i = 0; i < settings.length; i++) {
        let index = this.settings.findIndex((s) => s.Key === settings[i].Key)
        if (index > -1) {
          this.settings[index].ReceiveMail = false
          this.settings[index].EmailAddress = ''
        }
      }

      this.saveMailSettings()
    },
  },
}
</script>

<style></style>
